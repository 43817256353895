



















import { Prop, Vue, Component } from "vue-property-decorator";

@Component
export default class LabelsInfos extends Vue {
  @Prop({ type: Array, required: true }) labels: Array<Types.LabelItem>;
  @Prop(Boolean) all : boolean;
}
