

































































































































import { Vue, Component, Prop } from 'vue-property-decorator';

import { objectIsEmpty } from '~/utils/common.js';
import { eventType, getSportTags } from '~/utils/explorer.js';
import { dateOfYearFormat } from '~/utils/date.js';

import ProfileRunSportTag from '~/components/partials/ProfileRunSportTag.vue';
import EventCategoryTag from '~/components/partials/EventCategoryTag.vue';
import WarningOutline from '~/components/icons/WarningOutline.vue';
import XSolid from '~/components/icons/XSolid.vue';
import LabelsInfos from "~/components/partials/LabelsInfos.vue";

@Component({
  components: {
    LabelsInfos,
    ProfileRunSportTag,
    EventCategoryTag,
    WarningOutline,
    XSolid,
  },
})
export default class EventCard extends Vue {
  @Prop(Object) news: Types.Event;
  @Prop(Boolean) isInLocalisationContext: false;

  dateOfYearFormat = dateOfYearFormat;

  /*
    This tricky computed prevents SSR issues when fetching data
    on page load, as this.news can sometimes be null, and sometimes be {}
  */
  get canShow () {
    if (this.isInLocalisationContext && !objectIsEmpty(this.news)) {
      return true;
    } else if (!this.isInLocalisationContext) {
      return true;
    }
    return false;
  }

  /* Using util method to return translated event type */
  get eventType () {
    return eventType(this.news?.type);
  }

  /* Computed skeleton handle */
  get newsIsEmpty () {
    return objectIsEmpty(this.news);
  }

  /* Display an additional info is event is earlier than Date.now() */
  get newsHasPassed () {
    if (this.news.dates?.type === 'weekly') {
      const dateEvent = this.$dayjs(this.news.dates.range.end);
      const dateNow = this.$dayjs();
      return dateEvent.diff(dateNow, 'd') < 0;
    } else if (this.news.dates?.type === 'ranges') {
      const dateEvent = this.$dayjs(this.news.dates.ranges[this.news.dates.ranges.length - 1]?.end);
      const dateNow = this.$dayjs();
      return dateEvent.diff(dateNow, 'd') < 0;
    }
    return false;
  }

  /* Util method to get translated sport tags if they exist */
  get routeSportTags () {
    return getSportTags(this.news.sports);
  }

  mounted () {
    // This code resets the CSS inline styles of the description
    const description: any = document.getElementsByClassName('EventDescription');
    description.forEach((d: any) => {
      d.innerHTML = d.textContent;
    });
  }

  /* Image fallback if card <img @error> is triggered */
  imageFallback (event: any) {
    event.target.src = "/img/placeholders/event-placeholder.png";
  }

  /*
    This method will handle the date of the event to show, if is a range.
    If all dates are expired, the method returns the last one, or will return
    the first upcoming one
  */
  dateToShow (ranges: Types.EventStartEnd[]) {
    let dateToShow: string = '';
    const allDatesAreExpired: boolean = ranges.every((range: Types.EventStartEnd) => {
      const dateEvent = this.$dayjs(range.end);
      const dateNow = this.$dayjs();
      return dateEvent.diff(dateNow, 'd') < 0;
    });

    if (allDatesAreExpired) {
      return `${this.$dayjs(ranges[ranges.length - 1].start).format(this.dateOfYearFormat('/'))} - ${this.$dayjs(ranges[ranges.length - 1].end).format(this.dateOfYearFormat('/'))}`;
    } else {
      ranges.forEach((range: Types.EventStartEnd) => {
        const dateEvent = this.$dayjs(range.end);
        const dateNow = this.$dayjs();
        if (dateEvent.diff(dateNow, 'd') >= 0) {
          dateToShow = `${this.$dayjs(range.start).format(this.dateOfYearFormat('/'))} - ${this.$dayjs(range.end).format(this.dateOfYearFormat('/'))}`;
          /* eslint-disable-next-line */
          return;
        }
      });
      return dateToShow;
    }
  }
}
