export const years = () => {
  const max = new Date().getFullYear();
  const min = 1905;
  const years = [];

  for (let i = max; i >= min; i--) {
    years.push({ id: i, name: i });
  }
  return years;
};

export const months = [
  {
    id: 1,
    en: 'January',
    fr: 'Janvier',
    es: 'Enero',
    it: 'Gennaio',
    ca: 'Gener',
  },
  {
    id: 2,
    en: 'February',
    fr: 'Février',
    es: 'Febrero',
    it: 'Febbraio',
    ca: 'Febrer',
  },
  {
    id: 3,
    en: 'March',
    fr: 'Mars',
    es: 'Marzo',
    it: 'Marzo',
    ca: 'Març',
  },
  {
    id: 4,
    en: 'April',
    fr: 'Avril',
    es: 'Abril',
    it: 'Aprile',
    ca: 'Abril',
  },
  {
    id: 5,
    en: 'May',
    fr: 'Mai',
    es: 'Mayo',
    it: 'Maggio',
    ca: 'Maig',
  },
  {
    id: 6,
    en: 'June',
    fr: 'Juin',
    es: 'Junio',
    it: 'Giugno',
    ca: 'Juny',
  },
  {
    id: 7,
    en: 'July',
    fr: 'Juillet',
    es: 'Julio',
    it: 'Luglio',
    ca: 'Juliol',
  },
  {
    id: 8,
    en: 'August',
    fr: 'Août',
    es: 'Agosto',
    it: 'Agosto',
    ca: 'Agost',
  },
  {
    id: 9,
    en: 'September',
    fr: 'Septembre',
    es: 'Septiembre',
    it: 'Settembre',
    ca: 'Setembre',
  },
  {
    id: 10,
    en: 'October',
    fr: 'Octobre',
    es: 'Octubre',
    it: 'Ottobre',
    ca: 'Octubre',
  },
  {
    id: 11,
    en: 'November',
    fr: 'Novembre',
    es: 'Noviembre',
    it: 'Novembre',
    ca: 'Novembre',
  },
  {
    id: 12,
    en: 'December',
    fr: 'Décembre',
    es: 'Diciembre',
    it: 'Dicembre',
    ca: 'Desembre',
  },
];

export const days = () => {
  const days = [];

  for (let i = 1; i <= 31; i++) {
    days.push({ id: i, name: i });
  }

  return days;
};

export function dateOfYearFormat (separator) {
  const cookieValue = process.client ? $nuxt.$cookies.get('i18n_redirected') : 'en';
  const locale = cookieValue || 'en';
  switch (locale) {
    case 'fr':
    case 'es':
    case 'it':
    case 'ca': return `DD${separator}MM${separator}YYYY`;

    case 'en': return `MM${separator}DD${separator}YYYY`;
    default: return `YYYY${separator}MM${separator}DD`;
  }
}

export function dateOfMonthFormat () {
  const cookieValue = process.client ? $nuxt.$cookies.get('i18n_redirected') : 'en';
  const locale = cookieValue || 'en';
  switch (locale) {
    case 'fr':
    case 'es':
    case 'it':
    case 'ca': return `D MMM.`;

    case 'en': return `MMM. D`;
    default: return `D MMM.`;
  }
}

export function dateOfYearFormattedWithMonthName () {
  const cookieValue = process.client ? $nuxt.$cookies.get('i18n_redirected') : 'en';
  const locale = cookieValue || 'en';
  switch (locale) {
    case 'fr':
    case 'es':
    case 'it':
    case 'ca': return `DD MMM YYYY`;

    case 'en': return `MMM DD YYYY`;
    default: return `DD MMM YYYY`;
  }
}
