




























































import { Vue, Component, Prop } from 'vue-property-decorator';

import ChevronRightSolid from '~/components/icons/ChevronRightSolid.vue';
import BlogCard from '~/components/cards/BlogCard.vue';
import GlobalSwiper from '~/components/partials/GlobalSwiper.vue';

@Component({
  components: { ChevronRightSolid, BlogCard, GlobalSwiper },
})
export default class BlogFeed extends Vue {
  @Prop(Boolean) displayAsSwiper: false;
  @Prop(Array) blogNews: Array<Types.BlogNews>;
  @Prop(String) customTitle: '';
  @Prop({ type: Boolean, default: true }) showAllLinks: boolean;
  @Prop(String) customLink: '';

  staticBlogNews: Array<Types.BlogNews> = [];

  /* Skeleton length of fake cards to display based on user's window device */
  get skeletonLength () {
    if (process.client) {
      if (window.innerWidth < 1024 && window.innerWidth >= 768) return 3;
      return window.innerWidth < 768 ? 1 : 4;
    }
    return 4;
  }

  /* Returns the first news of the news list, based on its time */
  get firstLastNews () {
    return this.blogNews?.length ? this.sortByDate(this.blogNews)[0] : {};
  }

  /*  Get the 4 news following the first one */
  get fourLastNews () {
    if (this.blogNews?.length) {
      const news: Array<Types.BlogNews> = this.blogNews.slice(1, 5);
      return this.sortByDate(news);
    }
    return [{}, {}, {}, {}];
  }

  /* Util method to sort news array by date values */
  sortByDate (array: Array<any>) {
    return array?.sort((a: Types.BlogNews, b: Types.BlogNews) => {
      const dateA: any = a.date;
      const dateB: any = b.date;
      return dateA - dateB;
    });
  }
}
