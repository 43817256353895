










































import { Vue, Component, Prop } from 'vue-property-decorator';

import ChevronRightSolid from '~/components/icons/ChevronRightSolid.vue';
import EventCard from '~/components/cards/EventCard.vue';
import GlobalSwiper from '~/components/partials/GlobalSwiper.vue';
import MapOutline from '~/components/icons/MapOutline.vue';

@Component({
  components: { ChevronRightSolid, EventCard, MapOutline, GlobalSwiper },
})
export default class EventFeed extends Vue {
  @Prop(Array) eventNews: Array<Types.Event>;
  @Prop(String) customTitle: '';
  @Prop(String) linkToExplorer: '';

  /* Skeleton length of fake cards to display based on user's window device */
  get skeletonLength () {
    if (process.client) {
      if (window.innerWidth < 1024 && window.innerWidth >= 768) return 3;
      return window.innerWidth < 768 ? 1 : 4;
    }
    return 4;
  }

  get orderEventsByDate () {
    if (this.eventNews?.length) return this.eventNews;
    return [];
  }
}
