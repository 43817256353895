















































































import { Vue, Component, Prop } from 'vue-property-decorator';

import { getRunTag } from '~/utils/runConstants.js';
import { objectIsEmpty } from '~/utils/common.js';

import ProfileRunSportTag from '~/components/partials/ProfileRunSportTag.vue';
import EventCategoryTag from '~/components/partials/EventCategoryTag.vue';

@Component({
  components: { ProfileRunSportTag, EventCategoryTag },
})
export default class BlogCard extends Vue {
  @Prop(Object) news: any
  @Prop(Boolean) isLastNews: false;
  @Prop(Boolean) displayAsSwiper: false;

  tags: any = [];

  /* Computed skeleton handle */
  get newsIsEmpty () {
    return objectIsEmpty(this.news);
  }

  /* Always open blog card in blank page except if already in blog context */
  get preventClick () {
    return this.$route.name?.includes('blog') || this.$route.name === 'index';
  }

  mounted () {
    if (this.news.tags?.length) {
      this.news.tags.forEach((s: Types.SportItem) => {
        this.tags.push(getRunTag(s.id));
      });
    }

    // This code resets the CSS inline styles of the description
    const description: any = document.getElementsByClassName('BlogDescription');
    description.forEach((d: any) => {
      d.innerHTML = d.textContent;
    });
  }

  /* Image fallback if card <img @error> is triggered */
  imageFallback (event: any) {
    event.target.src = "/img/placeholders/event-placeholder.png";
  }

  /* Method util to window.open dynamically the link */
  openInBlank () {
    if (process.client) {
      window.open(`/blog/${this.news.category}/${this.news.id}`, '_blank');
    }
  }
}
